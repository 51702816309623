interface ElementoMapaCyan {
	codigo: string;
	nombre: string;
	nombrePadre?: string;
}




const departamentos: Record<string, ElementoMapaCyan> = {

	SV01: { codigo: 'SV01', nombre: 'AZUAY' },
	SV02: { codigo: 'SV02', nombre: 'BOLIVAR' },
	SV03: { codigo: 'SV03', nombre: 'CAÑAR' },
	SV04: { codigo: 'SV04', nombre: 'CARCHI' },
	SV05: { codigo: 'SV05', nombre: 'COTOPAXI' },
	SV06: { codigo: 'SV06', nombre: 'CHIMBORAZO' },
	SV07: { codigo: 'SV07', nombre: 'EL ORO' },
	SV08: { codigo: 'SV08', nombre: 'ESMERALDAS' },
	SV09: { codigo: 'SV09', nombre: 'GUAYAS' },
	SV10: { codigo: 'SV10', nombre: 'IMBABURA' },
	SV11: { codigo: 'SV11', nombre: 'LOJA' },
	SV12: { codigo: 'SV12', nombre: 'LOS RIOS' },
	SV13: { codigo: 'SV13', nombre: 'MANABI' },
	SV14: { codigo: 'SV14', nombre: 'MORONA SANTIAGO' },
	SV15: { codigo: 'SV15', nombre: 'NAPO' },
	SV16: { codigo: 'SV16', nombre: 'PASTAZA' },
	SV17: { codigo: 'SV17', nombre: 'PICHINCHA' },
	SV18: { codigo: 'SV18', nombre: 'TUNGURAHUA' },
	SV19: { codigo: 'SV19', nombre: 'ZAMORA CHINCHIPE' },
	SV20: { codigo: 'SV20', nombre: 'GALAPAGOS' },
	SV21: { codigo: 'SV21', nombre: 'SUCUMBIOS' },
	SV22: { codigo: 'SV22', nombre: 'ORELLANA' },
	SV23: { codigo: 'SV23', nombre: 'STO DGO TSACHILAS' },
	SV24: { codigo: 'SV24', nombre: 'SANTA ELENA' },
	
};

const municipios: Record<string, ElementoMapaCyan> = {
	SV0101: { codigo: 'SV0101', nombre: 'CAMILO PONCE ENRIQUEZ', nombrePadre: 'AZUAY' },
	SV0102: { codigo: 'SV0102', nombre: 'CHORDELEG', nombrePadre: 'AZUAY' },
	SV0103: { codigo: 'SV0103', nombre: 'CUENCA', nombrePadre: 'AZUAY' },
	SV0104: { codigo: 'SV0104', nombre: 'EL PAN', nombrePadre: 'AZUAY' },
	SV0105: { codigo: 'SV0105', nombre: 'GIRON', nombrePadre: 'AZUAY' },
	SV0106: { codigo: 'SV0106', nombre: 'GUACHAPALA', nombrePadre: 'AZUAY' },
	SV0107: { codigo: 'SV0107', nombre: 'GUALACEO', nombrePadre: 'AZUAY' },
	SV0108: { codigo: 'SV0108', nombre: 'NABON', nombrePadre: 'AZUAY' },
	SV0109: { codigo: 'SV0109', nombre: 'OÑA', nombrePadre: 'AZUAY' },
	SV0110: { codigo: 'SV0110', nombre: 'PAUTE', nombrePadre: 'AZUAY' },
	SV0111: { codigo: 'SV0111', nombre: 'PUCARA', nombrePadre: 'AZUAY' },
	SV0112: { codigo: 'SV0112', nombre: 'SAN FERNANDO', nombrePadre: 'AZUAY' },
	SV0113: { codigo: 'SV0113', nombre: 'SANTA ISABEL', nombrePadre: 'AZUAY' },
	SV0114: { codigo: 'SV0114', nombre: 'SEVILLA DE ORO', nombrePadre: 'AZUAY' },
	SV0115: { codigo: 'SV0115', nombre: 'SIGSIG', nombrePadre: 'AZUAY' },
	SV0201: { codigo: 'SV0201', nombre: 'CALUMA', nombrePadre: 'BOLIVAR' },
	SV0202: { codigo: 'SV0202', nombre: 'CHILLANES', nombrePadre: 'BOLIVAR' },
	SV0203: { codigo: 'SV0203', nombre: 'CHIMBO', nombrePadre: 'BOLIVAR' },
	SV0204: { codigo: 'SV0204', nombre: 'ECHEANDIA', nombrePadre: 'BOLIVAR' },
	SV0205: { codigo: 'SV0205', nombre: 'GUARANDA', nombrePadre: 'BOLIVAR' },
	SV0206: { codigo: 'SV0206', nombre: 'LAS NAVES', nombrePadre: 'BOLIVAR' },
	SV0207: { codigo: 'SV0207', nombre: 'SAN MIGUEL', nombrePadre: 'BOLIVAR' },
	SV0301: { codigo: 'SV0301', nombre: 'AZOGUES', nombrePadre: 'CAÑAR' },
	SV0302: { codigo: 'SV0302', nombre: 'BIBLIAN', nombrePadre: 'CAÑAR' },
	SV0303: { codigo: 'SV0303', nombre: 'CAÑAR', nombrePadre: 'CAÑAR' },
	SV0304: { codigo: 'SV0304', nombre: 'DELEG', nombrePadre: 'CAÑAR' },
	SV0305: { codigo: 'SV0305', nombre: 'EL TAMBO', nombrePadre: 'CAÑAR' },
	SV0306: { codigo: 'SV0306', nombre: 'LA TRONCAL', nombrePadre: 'CAÑAR' },
	SV0307: { codigo: 'SV0307', nombre: 'SUSCAL', nombrePadre: 'CAÑAR' },
	SV0401: { codigo: 'SV0401', nombre: 'BOLIVAR', nombrePadre: 'CARCHI' },
	SV0402: { codigo: 'SV0402', nombre: 'ESPEJO', nombrePadre: 'CARCHI' },
	SV0403: { codigo: 'SV0403', nombre: 'MIRA', nombrePadre: 'CARCHI' },
	SV0404: { codigo: 'SV0404', nombre: 'MONTUFAR', nombrePadre: 'CARCHI' },
	SV0405: { codigo: 'SV0405', nombre: 'SAN PEDRO DE HUACA', nombrePadre: 'CARCHI' },
	SV0406: { codigo: 'SV0406', nombre: 'TULCAN', nombrePadre: 'CARCHI' },
	SV0501: { codigo: 'SV0501', nombre: 'LA MANA', nombrePadre: 'COTOPAXI' },
	SV0502: { codigo: 'SV0502', nombre: 'LATACUNGA', nombrePadre: 'COTOPAXI' },
	SV0503: { codigo: 'SV0503', nombre: 'PANGUA', nombrePadre: 'COTOPAXI' },
	SV0504: { codigo: 'SV0504', nombre: 'PUJILI', nombrePadre: 'COTOPAXI' },
	SV0505: { codigo: 'SV0505', nombre: 'SALCEDO', nombrePadre: 'COTOPAXI' },
	SV0506: { codigo: 'SV0506', nombre: 'SAQUISILI', nombrePadre: 'COTOPAXI' },
	SV0507: { codigo: 'SV0507', nombre: 'SIGCHOS', nombrePadre: 'COTOPAXI' },
	SV0601: { codigo: 'SV0601', nombre: 'ALAUSI', nombrePadre: 'CHIMBORAZO' },
	SV0602: { codigo: 'SV0602', nombre: 'CHAMBO', nombrePadre: 'CHIMBORAZO' },
	SV0603: { codigo: 'SV0603', nombre: 'CHUNCHI', nombrePadre: 'CHIMBORAZO' },
	SV0604: { codigo: 'SV0604', nombre: 'COLTA', nombrePadre: 'CHIMBORAZO' },
	SV0605: { codigo: 'SV0605', nombre: 'CUMANDA', nombrePadre: 'CHIMBORAZO' },
	SV0606: { codigo: 'SV0606', nombre: 'GUAMOTE', nombrePadre: 'CHIMBORAZO' },
	SV0607: { codigo: 'SV0607', nombre: 'GUANO', nombrePadre: 'CHIMBORAZO' },
	SV0608: { codigo: 'SV0608', nombre: 'PALLATANGA', nombrePadre: 'CHIMBORAZO' },
	SV0609: { codigo: 'SV0609', nombre: 'PENIPE', nombrePadre: 'CHIMBORAZO' },
	SV0610: { codigo: 'SV0610', nombre: 'RIOBAMBA', nombrePadre: 'CHIMBORAZO' },
	SV0701: { codigo: 'SV0701', nombre: 'ARENILLAS', nombrePadre: 'EL ORO' },
	SV0702: { codigo: 'SV0702', nombre: 'ATAHUALPA', nombrePadre: 'EL ORO' },
	SV0703: { codigo: 'SV0703', nombre: 'BALSAS', nombrePadre: 'EL ORO' },
	SV0704: { codigo: 'SV0704', nombre: 'CHILLA', nombrePadre: 'EL ORO' },
	SV0705: { codigo: 'SV0705', nombre: 'EL GUABO', nombrePadre: 'EL ORO' },
	SV0706: { codigo: 'SV0706', nombre: 'HUAQUILLAS', nombrePadre: 'EL ORO' },
	SV0707: { codigo: 'SV0707', nombre: 'LAS LAJAS', nombrePadre: 'EL ORO' },
	SV0708: { codigo: 'SV0708', nombre: 'MACHALA', nombrePadre: 'EL ORO' },
	SV0709: { codigo: 'SV0709', nombre: 'MARCABELI', nombrePadre: 'EL ORO' },
	SV0710: { codigo: 'SV0710', nombre: 'PASAJE', nombrePadre: 'EL ORO' },
	SV0711: { codigo: 'SV0711', nombre: 'PIÑAS', nombrePadre: 'EL ORO' },
	SV0712: { codigo: 'SV0712', nombre: 'PORTOVELO', nombrePadre: 'EL ORO' },
	SV0713: { codigo: 'SV0713', nombre: 'SANTA ROSA', nombrePadre: 'EL ORO' },
	SV0714: { codigo: 'SV0714', nombre: 'ZARUMA', nombrePadre: 'EL ORO' },
	SV0801: { codigo: 'SV0801', nombre: 'ATACAMES', nombrePadre: 'ESMERALDAS' },
	SV0802: { codigo: 'SV0802', nombre: 'ELOY ALFARO', nombrePadre: 'ESMERALDAS' },
	SV0803: { codigo: 'SV0803', nombre: 'ESMERALDAS', nombrePadre: 'ESMERALDAS' },
	SV0804: { codigo: 'SV0804', nombre: 'MUISNE', nombrePadre: 'ESMERALDAS' },
	SV0805: { codigo: 'SV0805', nombre: 'QUININDE', nombrePadre: 'ESMERALDAS' },
	SV0806: { codigo: 'SV0806', nombre: 'RIO VERDE', nombrePadre: 'ESMERALDAS' },
	SV0807: { codigo: 'SV0807', nombre: 'SAN LORENZO', nombrePadre: 'ESMERALDAS' },
	SV0901: { codigo: 'SV0901', nombre: 'A.BAQUERIZO MORENO', nombrePadre: 'GUAYAS' },
	SV0902: { codigo: 'SV0902', nombre: 'BALAO', nombrePadre: 'GUAYAS' },
	SV0903: { codigo: 'SV0903', nombre: 'BALZAR', nombrePadre: 'GUAYAS' },
	SV0904: { codigo: 'SV0904', nombre: 'COLIMES', nombrePadre: 'GUAYAS' },
	SV0905: { codigo: 'SV0905', nombre: 'CRNL MARCELINO MARIDUENAS', nombrePadre: 'GUAYAS' },
	SV0906: { codigo: 'SV0906', nombre: 'DAULE', nombrePadre: 'GUAYAS' },
	SV0907: { codigo: 'SV0907', nombre: 'DURAN', nombrePadre: 'GUAYAS' },
	SV0908: { codigo: 'SV0908', nombre: 'EL EMPALME', nombrePadre: 'GUAYAS' },
	SV0909: { codigo: 'SV0909', nombre: 'EL TRIUNFO', nombrePadre: 'GUAYAS' },
	SV0910: { codigo: 'SV0910', nombre: 'GRAL. A. ELIZALDE', nombrePadre: 'GUAYAS' },
	SV0911: { codigo: 'SV0911', nombre: 'GUAYAQUIL', nombrePadre: 'GUAYAS' },
	SV0912: { codigo: 'SV0912', nombre: 'ISIDRO AYORA', nombrePadre: 'GUAYAS' },
	SV0913: { codigo: 'SV0913', nombre: 'LOMAS DE SARGENTILLO', nombrePadre: 'GUAYAS' },
	SV0914: { codigo: 'SV0914', nombre: 'MILAGRO', nombrePadre: 'GUAYAS' },
	SV0915: { codigo: 'SV0915', nombre: 'NARANJAL', nombrePadre: 'GUAYAS' },
	SV0916: { codigo: 'SV0916', nombre: 'NARANJITO', nombrePadre: 'GUAYAS' },
	SV0917: { codigo: 'SV0917', nombre: 'NOBOL / PIEDRAHITA', nombrePadre: 'GUAYAS' },
	SV0918: { codigo: 'SV0918', nombre: 'PALESTINA', nombrePadre: 'GUAYAS' },
	SV0919: { codigo: 'SV0919', nombre: 'PEDRO CARBO', nombrePadre: 'GUAYAS' },
	SV0920: { codigo: 'SV0920', nombre: 'PLAYAS', nombrePadre: 'GUAYAS' },
	SV0921: { codigo: 'SV0921', nombre: 'SALITRE', nombrePadre: 'GUAYAS' },
	SV0922: { codigo: 'SV0922', nombre: 'SAMBORONDON', nombrePadre: 'GUAYAS' },
	SV0923: { codigo: 'SV0923', nombre: 'SANTA LUCIA', nombrePadre: 'GUAYAS' },
	SV0924: { codigo: 'SV0924', nombre: 'SIMON BOLIVAR', nombrePadre: 'GUAYAS' },
	SV0925: { codigo: 'SV0925', nombre: 'YAGUACHI', nombrePadre: 'GUAYAS' },
	SV1001: { codigo: 'SV1001', nombre: 'ANTONIO ANTE', nombrePadre: 'IMBABURA' },
	SV1002: { codigo: 'SV1002', nombre: 'COTACACHI', nombrePadre: 'IMBABURA' },
	SV1003: { codigo: 'SV1003', nombre: 'IBARRA', nombrePadre: 'IMBABURA' },
	SV1004: { codigo: 'SV1004', nombre: 'OTAVALO', nombrePadre: 'IMBABURA' },
	SV1005: { codigo: 'SV1005', nombre: 'PIMAMPIRO', nombrePadre: 'IMBABURA' },
	SV1006: { codigo: 'SV1006', nombre: 'URCUQUI', nombrePadre: 'IMBABURA' },
	SV1101: { codigo: 'SV1101', nombre: 'CALVAS', nombrePadre: 'LOJA' },
	SV1102: { codigo: 'SV1102', nombre: 'CATAMAYO', nombrePadre: 'LOJA' },
	SV1103: { codigo: 'SV1103', nombre: 'CELICA', nombrePadre: 'LOJA' },
	SV1104: { codigo: 'SV1104', nombre: 'CHAGUARPAMBA', nombrePadre: 'LOJA' },
	SV1105: { codigo: 'SV1105', nombre: 'ESPINDOLA', nombrePadre: 'LOJA' },
	SV1106: { codigo: 'SV1106', nombre: 'GONZANAMA', nombrePadre: 'LOJA' },
	SV1107: { codigo: 'SV1107', nombre: 'LOJA', nombrePadre: 'LOJA' },
	SV1108: { codigo: 'SV1108', nombre: 'MACARA', nombrePadre: 'LOJA' },
	SV1109: { codigo: 'SV1109', nombre: 'OLMEDO', nombrePadre: 'LOJA' },
	SV1110: { codigo: 'SV1110', nombre: 'PALTAS', nombrePadre: 'LOJA' },
	SV1111: { codigo: 'SV1111', nombre: 'PINDAL', nombrePadre: 'LOJA' },
	SV1112: { codigo: 'SV1112', nombre: 'PUYANGO', nombrePadre: 'LOJA' },
	SV1113: { codigo: 'SV1113', nombre: 'QUILANGA', nombrePadre: 'LOJA' },
	SV1114: { codigo: 'SV1114', nombre: 'SARAGURO', nombrePadre: 'LOJA' },
	SV1115: { codigo: 'SV1115', nombre: 'SOZORANGA', nombrePadre: 'LOJA' },
	SV1116: { codigo: 'SV1116', nombre: 'ZAPOTILLO', nombrePadre: 'LOJA' },
	SV1201: { codigo: 'SV1201', nombre: 'BABA', nombrePadre: 'LOS RIOS' },
	SV1202: { codigo: 'SV1202', nombre: 'BABAHOYO', nombrePadre: 'LOS RIOS' },
	SV1203: { codigo: 'SV1203', nombre: 'BUENA FE', nombrePadre: 'LOS RIOS' },
	SV1204: { codigo: 'SV1204', nombre: 'MOCACHE', nombrePadre: 'LOS RIOS' },
	SV1205: { codigo: 'SV1205', nombre: 'MONTALVO', nombrePadre: 'LOS RIOS' },
	SV1206: { codigo: 'SV1206', nombre: 'PALENQUE', nombrePadre: 'LOS RIOS' },
	SV1207: { codigo: 'SV1207', nombre: 'PUEBLO VIEJO', nombrePadre: 'LOS RIOS' },
	SV1208: { codigo: 'SV1208', nombre: 'QUEVEDO', nombrePadre: 'LOS RIOS' },
	SV1209: { codigo: 'SV1209', nombre: 'QUINSALOMA', nombrePadre: 'LOS RIOS' },
	SV1210: { codigo: 'SV1210', nombre: 'URDANETA', nombrePadre: 'LOS RIOS' },
	SV1211: { codigo: 'SV1211', nombre: 'VALENCIA', nombrePadre: 'LOS RIOS' },
	SV1212: { codigo: 'SV1212', nombre: 'VENTANAS', nombrePadre: 'LOS RIOS' },
	SV1213: { codigo: 'SV1213', nombre: 'VINCES', nombrePadre: 'LOS RIOS' },
	SV1301: { codigo: 'SV1301', nombre: '24 DE MAYO', nombrePadre: 'MANABI' },
	SV1302: { codigo: 'SV1302', nombre: 'BOLIVAR', nombrePadre: 'MANABI' },
	SV1303: { codigo: 'SV1303', nombre: 'CHONE', nombrePadre: 'MANABI' },
	SV1304: { codigo: 'SV1304', nombre: 'EL CARMEN', nombrePadre: 'MANABI' },
	SV1305: { codigo: 'SV1305', nombre: 'FLAVIO ALFARO', nombrePadre: 'MANABI' },
	SV1306: { codigo: 'SV1306', nombre: 'JAMA', nombrePadre: 'MANABI' },
	SV1307: { codigo: 'SV1307', nombre: 'JARAMIJO', nombrePadre: 'MANABI' },
	SV1308: { codigo: 'SV1308', nombre: 'JIPIJAPA', nombrePadre: 'MANABI' },
	SV1309: { codigo: 'SV1309', nombre: 'JUNIN', nombrePadre: 'MANABI' },
	SV1310: { codigo: 'SV1310', nombre: 'MANTA', nombrePadre: 'MANABI' },
	SV1311: { codigo: 'SV1311', nombre: 'MONTECRISTI', nombrePadre: 'MANABI' },
	SV1312: { codigo: 'SV1312', nombre: 'OLMEDO', nombrePadre: 'MANABI' },
	SV1313: { codigo: 'SV1313', nombre: 'PAJAN', nombrePadre: 'MANABI' },
	SV1314: { codigo: 'SV1314', nombre: 'PEDERNALES', nombrePadre: 'MANABI' },
	SV1315: { codigo: 'SV1315', nombre: 'PICHINCHA', nombrePadre: 'MANABI' },
	SV1316: { codigo: 'SV1316', nombre: 'PORTOVIEJO', nombrePadre: 'MANABI' },
	SV1317: { codigo: 'SV1317', nombre: 'PUERTO LOPEZ', nombrePadre: 'MANABI' },
	SV1318: { codigo: 'SV1318', nombre: 'ROCAFUERTE', nombrePadre: 'MANABI' },
	SV1319: { codigo: 'SV1319', nombre: 'SAN VICENTE', nombrePadre: 'MANABI' },
	SV1320: { codigo: 'SV1320', nombre: 'SANTA ANA', nombrePadre: 'MANABI' },
	SV1321: { codigo: 'SV1321', nombre: 'SUCRE', nombrePadre: 'MANABI' },
	SV1322: { codigo: 'SV1322', nombre: 'TOSAGUA', nombrePadre: 'MANABI' },
	SV1401: { codigo: 'SV1401', nombre: 'GUALAQUIZA', nombrePadre: 'MORONA SANTIAGO' },
	SV1402: { codigo: 'SV1402', nombre: 'HUAMBOYA', nombrePadre: 'MORONA SANTIAGO' },
	SV1403: { codigo: 'SV1403', nombre: 'LIMON INDANZA', nombrePadre: 'MORONA SANTIAGO' },
	SV1404: { codigo: 'SV1404', nombre: 'LOGROÑO', nombrePadre: 'MORONA SANTIAGO' },
	SV1405: { codigo: 'SV1405', nombre: 'MORONA', nombrePadre: 'MORONA SANTIAGO' },
	SV1406: { codigo: 'SV1406', nombre: 'PABLO SEXTO', nombrePadre: 'MORONA SANTIAGO' },
	SV1407: { codigo: 'SV1407', nombre: 'PALORA', nombrePadre: 'MORONA SANTIAGO' },
	SV1408: { codigo: 'SV1408', nombre: 'SAN JUAN BOSCO', nombrePadre: 'MORONA SANTIAGO' },
	SV1409: { codigo: 'SV1409', nombre: 'SANTIAGO', nombrePadre: 'MORONA SANTIAGO' },
	SV1410: { codigo: 'SV1410', nombre: 'SUCUA', nombrePadre: 'MORONA SANTIAGO' },
	SV1411: { codigo: 'SV1411', nombre: 'TAISHA', nombrePadre: 'MORONA SANTIAGO' },
	SV1412: { codigo: 'SV1412', nombre: 'TIWINTZA', nombrePadre: 'MORONA SANTIAGO' },
	SV1501: { codigo: 'SV1501', nombre: 'ARCHIDONA', nombrePadre: 'NAPO' },
	SV1502: { codigo: 'SV1502', nombre: 'C. J. AROSEMENA TOLA', nombrePadre: 'NAPO' },
	SV1503: { codigo: 'SV1503', nombre: 'EL CHACO', nombrePadre: 'NAPO' },
	SV1504: { codigo: 'SV1504', nombre: 'QUIJOS', nombrePadre: 'NAPO' },
	SV1505: { codigo: 'SV1505', nombre: 'TENA', nombrePadre: 'NAPO' },
	SV1601: { codigo: 'SV1601', nombre: 'ARAJUNO', nombrePadre: 'PASTAZA' },
	SV1602: { codigo: 'SV1602', nombre: 'MERA', nombrePadre: 'PASTAZA' },
	SV1603: { codigo: 'SV1603', nombre: 'PASTAZA', nombrePadre: 'PASTAZA' },
	SV1604: { codigo: 'SV1604', nombre: 'SANTA CLARA', nombrePadre: 'PASTAZA' },
	SV1701: { codigo: 'SV1701', nombre: 'CAYAMBE', nombrePadre: 'PICHINCHA' },
	SV1702: { codigo: 'SV1702', nombre: 'MEJIA', nombrePadre: 'PICHINCHA' },
	SV1703: { codigo: 'SV1703', nombre: 'PEDRO MONCAYO', nombrePadre: 'PICHINCHA' },
	SV1704: { codigo: 'SV1704', nombre: 'PEDRO VICENTE MALDONADO', nombrePadre: 'PICHINCHA' },
	SV1705: { codigo: 'SV1705', nombre: 'PUERTO QUITO', nombrePadre: 'PICHINCHA' },
	SV1706: { codigo: 'SV1706', nombre: 'QUITO', nombrePadre: 'PICHINCHA' },
	SV1707: { codigo: 'SV1707', nombre: 'RUMIÑAHUI', nombrePadre: 'PICHINCHA' },
	SV1708: { codigo: 'SV1708', nombre: 'SAN MIGUEL DE LOS BANCOS', nombrePadre: 'PICHINCHA' },
	SV1801: { codigo: 'SV1801', nombre: 'AMBATO', nombrePadre: 'TUNGURAHUA' },
	SV1802: { codigo: 'SV1802', nombre: 'BAÑOS', nombrePadre: 'TUNGURAHUA' },
	SV1803: { codigo: 'SV1803', nombre: 'CEVALLOS', nombrePadre: 'TUNGURAHUA' },
	SV1804: { codigo: 'SV1804', nombre: 'MOCHA', nombrePadre: 'TUNGURAHUA' },
	SV1805: { codigo: 'SV1805', nombre: 'PATATE', nombrePadre: 'TUNGURAHUA' },
	SV1806: { codigo: 'SV1806', nombre: 'PELILEO', nombrePadre: 'TUNGURAHUA' },
	SV1807: { codigo: 'SV1807', nombre: 'PILLARO', nombrePadre: 'TUNGURAHUA' },
	SV1808: { codigo: 'SV1808', nombre: 'QUERO', nombrePadre: 'TUNGURAHUA' },
	SV1809: { codigo: 'SV1809', nombre: 'TISALEO', nombrePadre: 'TUNGURAHUA' },
	SV1901: { codigo: 'SV1901', nombre: 'CENTINELA DEL CONDOR', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1902: { codigo: 'SV1902', nombre: 'CHINCHIPE', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1903: { codigo: 'SV1903', nombre: 'EL PANGUI', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1904: { codigo: 'SV1904', nombre: 'NANGARITZA', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1905: { codigo: 'SV1905', nombre: 'PALANDA', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1906: { codigo: 'SV1906', nombre: 'PAQUISHA', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1907: { codigo: 'SV1907', nombre: 'YACUAMBI', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1908: { codigo: 'SV1908', nombre: 'YANZATZA', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV1909: { codigo: 'SV1909', nombre: 'ZAMORA', nombrePadre: 'ZAMORA CHINCHIPE' },
	SV2001: { codigo: 'SV2001', nombre: 'ISABELA', nombrePadre: 'GALAPAGOS' },
	SV2002: { codigo: 'SV2002', nombre: 'SAN CRISTOBAL', nombrePadre: 'GALAPAGOS' },
	SV2003: { codigo: 'SV2003', nombre: 'SANTA CRUZ', nombrePadre: 'GALAPAGOS' },
	SV2101: { codigo: 'SV2101', nombre: 'CASCALES', nombrePadre: 'SUCUMBIOS' },
	SV2102: { codigo: 'SV2102', nombre: 'CUYABENO', nombrePadre: 'SUCUMBIOS' },
	SV2103: { codigo: 'SV2103', nombre: 'GONZALO PIZARRO', nombrePadre: 'SUCUMBIOS' },
	SV2104: { codigo: 'SV2104', nombre: 'LAGO AGRIO', nombrePadre: 'SUCUMBIOS' },
	SV2105: { codigo: 'SV2105', nombre: 'PUTUMAYO', nombrePadre: 'SUCUMBIOS' },
	SV2106: { codigo: 'SV2106', nombre: 'SHUSHUFINDI', nombrePadre: 'SUCUMBIOS' },
	SV2107: { codigo: 'SV2107', nombre: 'SUCUMBIOS', nombrePadre: 'SUCUMBIOS' },
	SV2201: { codigo: 'SV2201', nombre: 'AGUARICO', nombrePadre: 'ORELLANA' },
	SV2202: { codigo: 'SV2202', nombre: 'FCO.DE ORELLANA', nombrePadre: 'ORELLANA' },
	SV2203: { codigo: 'SV2203', nombre: 'JOYA DE LOS SACHAS', nombrePadre: 'ORELLANA' },
	SV2204: { codigo: 'SV2204', nombre: 'LORETO', nombrePadre: 'ORELLANA' },
	SV2301: { codigo: 'SV2301', nombre: 'LA CONCORDIA', nombrePadre: 'STO DGO TSACHILAS' },
	SV2302: { codigo: 'SV2302', nombre: 'SANTO DOMINGO', nombrePadre: 'STO DGO TSACHILAS' },
	SV2401: { codigo: 'SV2401', nombre: 'LA LIBERTAD', nombrePadre: 'SANTA ELENA' },
	SV2402: { codigo: 'SV2402', nombre: 'SALINAS', nombrePadre: 'SANTA ELENA' },
	SV2403: { codigo: 'SV2403', nombre: 'SANTA ELENA', nombrePadre: 'SANTA ELENA' },

};

const mapa: Record<string, string[]> = {
	SV:   [ 'SV01', 'SV02', 'SV03', 'SV04', 'SV05', 'SV06', 'SV07', 'SV08', 'SV09', 'SV10', 'SV11', 'SV12', 'SV13', 'SV14', 'SV15', 'SV16', 'SV17', 'SV18', 'SV19', 'SV20', 'SV21', 'SV22', 'SV23', 'SV24' ],
	SV01: [ 'SV0101', 'SV0102', 'SV0103', 'SV0104', 'SV0105', 'SV0106', 'SV0107', 'SV0108', 'SV0109', 'SV0110', 'SV0111', 'SV0112', 'SV0113', 'SV0114', 'SV0115' ],
	SV02: [ 'SV0201', 'SV0202', 'SV0203', 'SV0204', 'SV0205', 'SV0206', 'SV0207' ],
	SV03: [ 'SV0301', 'SV0302', 'SV0303', 'SV0304', 'SV0305', 'SV0306', 'SV0307' ],
	SV04: [ 'SV0401', 'SV0402', 'SV0403', 'SV0404', 'SV0405', 'SV0406' ],
	SV05: [ 'SV0501', 'SV0502', 'SV0503', 'SV0504', 'SV0505', 'SV0506', 'SV0507' ],
	SV06: [ 'SV0601', 'SV0602', 'SV0603', 'SV0604', 'SV0605', 'SV0606', 'SV0607', 'SV0608', 'SV0609', 'SV0610' ],
	SV07: [ 'SV0701', 'SV0702', 'SV0703', 'SV0704', 'SV0705', 'SV0706', 'SV0707', 'SV0708', 'SV0709', 'SV0710', 'SV0711', 'SV0712', 'SV0713', 'SV0714' ],
	SV08: [ 'SV0801', 'SV0802', 'SV0803', 'SV0804', 'SV0805', 'SV0806', 'SV0807' ],
	SV09: [ 'SV0901', 'SV0902', 'SV0903', 'SV0904', 'SV0905', 'SV0906', 'SV0907', 'SV0908', 'SV0909', 'SV0910', 'SV0911', 'SV0912', 'SV0913', 'SV0914', 'SV0915', 'SV0916', 'SV0917', 'SV0918', 'SV0919', 'SV0920', 'SV0921', 'SV0922', 'SV0923', 'SV0924', 'SV0925' ],
	SV10: [ 'SV1001', 'SV1002', 'SV1003', 'SV1004', 'SV1005', 'SV1006' ],
	SV11: [ 'SV1101', 'SV1102', 'SV1103', 'SV1104', 'SV1105', 'SV1106', 'SV1107', 'SV1108', 'SV1109', 'SV1110', 'SV1111', 'SV1112', 'SV1113', 'SV1114', 'SV1115', 'SV1116' ],
	SV12: [ 'SV1201', 'SV1202', 'SV1203', 'SV1204', 'SV1205', 'SV1206', 'SV1207', 'SV1208', 'SV1209', 'SV1210', 'SV1211', 'SV1212', 'SV1213' ],
	SV13: [ 'SV1301', 'SV1302', 'SV1303', 'SV1304', 'SV1305', 'SV1306', 'SV1307', 'SV1308', 'SV1309', 'SV1310', 'SV1311', 'SV1312', 'SV1313', 'SV1314', 'SV1315', 'SV1316', 'SV1317', 'SV1318', 'SV1319', 'SV1320', 'SV1321', 'SV1322' ],
	SV14: [ 'SV1401', 'SV1402', 'SV1403', 'SV1404', 'SV1405', 'SV1406', 'SV1407', 'SV1408', 'SV1409', 'SV1410', 'SV1411', 'SV1412' ],
	SV15: [ 'SV1501', 'SV1502', 'SV1503', 'SV1504', 'SV1505' ],
	SV16: [ 'SV1601', 'SV1602', 'SV1603', 'SV1604' ],
	SV17: [ 'SV1701', 'SV1702', 'SV1703', 'SV1704', 'SV1705', 'SV1706', 'SV1707', 'SV1708' ],
	SV18: [ 'SV1801', 'SV1802', 'SV1803', 'SV1804', 'SV1805', 'SV1806', 'SV1807', 'SV1808', 'SV1809' ],
	SV19: [ 'SV1901', 'SV1902', 'SV1903', 'SV1904', 'SV1905', 'SV1906', 'SV1907', 'SV1908', 'SV1909' ],
	SV20: [ 'SV2001', 'SV2002', 'SV2003' ],
	SV21: [ 'SV2101', 'SV2102', 'SV2103', 'SV2104', 'SV2105', 'SV2106', 'SV2107' ],
	SV22: [ 'SV2201', 'SV2202', 'SV2203', 'SV2204' ],
	SV23: [ 'SV2301', 'SV2302' ],
	SV24: [ 'SV2401', 'SV2402', 'SV2403' ],
	};

const territorios = {
	SV: { codigo: 'SV', nombre: 'ECUADOR' },
	...departamentos,
	...municipios
}

const cyanRegions = {
	departamentos,
	municipios,
	mapa,
	territorios
}

export { territorios, departamentos, municipios, mapa };
export default cyanRegions;

